<template>
  <div>
    <p>
      LEGO® Universe Closed BETA Testing has begun! Fans from all over Earth are playing the game and helping to polish its performance!
    </p>
    <p>
      Tell us if you’re in the Closed BETA!
    </p>
    <poll-images id="00579cc7-4fd9-42d6-ad8b-1fce51718405"></poll-images>
    <poll id="00579cc7-4fd9-42d6-ad8b-1fce51718405"></poll>
  </div>
</template>
